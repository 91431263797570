import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

const Login = () => {
  const dispatch = useDispatch();
  const handleLogin = () => {
    dispatch.app.showPopupLogin({ positionLogin: "PAGE_LOGIN" });
  };
  useEffect(() => {
    handleLogin();
  }, []);
  return <div style={{background: '#2972cb', width: '100vw', height: '100vh'}}/>;
};

export default Login
